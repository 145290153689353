import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import get from 'lodash/get'
import styled from '@emotion/styled'
import Layout from '../components/layout'
import { HeadingLevel1 } from '../components/Headings'
import { ArticleStyled as Article, ArticleContentStyled as ArticleContent } from '../styles/article'
import { contentWidth } from '../Variables/Contents'
import { mediaQuery } from '../Variables/Contents'
import BlogInfo from '../components/blog-info'
import Hero from '../components/hero'

const ContentWrapper = styled.div`
  padding: 15px;

  @media (${mediaQuery.tb}) {
    margin: auto;
    max-width: ${contentWidth.pc};
    padding: 30px;
  }
`
class BlogPostTemplate extends React.Component {
  render() {
    const post = get(this.props, 'data.contentfulBlogPost')
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')

    return (
      <Layout location={this.props.location}>
        <Hero type='detail' />
        <ContentWrapper>
          <Article>
            <section>
              <Helmet title={`${post.title} | ${siteTitle}`} />
                <HeadingLevel1 type='detail'>{post.title}</HeadingLevel1>
                <BlogInfo data={post.publishDate}  items={post.tags} />
                <ArticleContent
                  type='detail'
                  dangerouslySetInnerHTML={{
                    __html: post.body.childMarkdownRemark.html,
                  }}
                />
            </section>
          </Article>
        </ContentWrapper>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      publishDate(formatString: "YYYY/MM/DD")
      tags
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
